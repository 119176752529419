




























































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const StudentQuestionAnswersModule = namespace("student-question-answers");

@Component({
  components: {},
})
export default class PreliminaryExamImage extends Vue {
  private get examProgress() {
    /** accepts values between 0 - 4 which are * 25%  for every sector. Bigger values brake the graph **/
    return (this.education?.consecutiveSuccessfulPreExams >= 4 ? 4 : this.education?.consecutiveSuccessfulPreExams) || 0;
  }

  @StudentQuestionAnswersModule.Getter("getStats")
  public stats: any;

  @Prop()
  public education!: any;

  @Prop()
  public learnStatus!: any;

  public percentPositionX = 20;

  public get percentPreExamsDone(): number {
    if (this.examProgress) {
      return this.examProgress * 25;
    }
    return 0;
  }

  public get getLearnStatus() {
    //if (this.stats && !this.stats.correctlyAnswered) return 0;
    return this.learnStatus || 0;
  }

  @Watch("examProgress", { immediate: true, deep: true })
  private onCountPreExamsDoneChange(count: any): void {
    switch (count) {
      case 1:
        this.percentPositionX = 12;
        break;
      case 2:
        this.percentPositionX = 12;
        break;
      case 3:
        this.percentPositionX = 12;
        break;
      case 4:
        this.percentPositionX = 4;
        break;
      default:
        this.percentPositionX = 20;
        break;
    }
  }
}
