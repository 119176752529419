















































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const StudentQuestionAnswersModule = namespace("student-question-answers");

@Component({
  components: {},
})
export default class StudentQuestionsImgPositive extends Vue {
  @Prop()
  public learningMode!: any;
  @Prop()
  public stats: any;
  @Prop()
  public loading: any;

  get incorrectlyAnsweredX(): number {
    if (!this.stats || !this.stats.incorrectlyAnswered) {
      return -21;
    }
    if (this.stats.incorrectlyAnswered >= 100) {
      return -42;
    }
    if (this.stats.incorrectlyAnswered >= 10) {
      return -32;
    }
    return -21;
  }

  get readyForExamX(): number {
    if (!this.stats || !this.stats.readyForExam) {
      return -5;
    }
    if (this.stats.readyForExam >= 100) {
      return -26;
    }
    if (this.stats.readyForExam >= 10) {
      return -16;
    }
    return -8;
  }

  get oneTimeAnsweredX(): number {
    if (!this.stats || !this.stats.correctlyAnswered) {
      return 18;
    }
    if (this.stats.correctlyAnswered >= 100) {
      return -2;
    }
    if (this.stats.correctlyAnswered >= 10) {
      return 8;
    }
    return 18;
  }

  get remainingMidX(): number {
    if (!this.stats || !this.stats.remaining) {
      return 18;
    }
    if (this.stats.remaining >= 1000) {
      return -12;
    }
    if (this.stats.remaining >= 100) {
      return -2;
    }
    if (this.stats.remaining >= 10) {
      return 8;
    }
    return 18;
  }

  get remainingX(): number {
    if (!this.stats || !this.stats.remaining) {
      return 18;
    }
    if (this.stats.remaining >= 1000) {
      return -12;
    }
    if (this.stats.remaining >= 100) {
      return -2;
    }
    if (this.stats.remaining >= 10) {
      return 8;
    }
    return 18;
  }
}
