







































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Table from "@/components/Table.vue";
import PreExamsListQuestions from "@/views/Student/LearnStatus/PreExamsList/PreExamsListQuestions.vue";
import { namespace } from "vuex-class";

const TheoryPreExamsModule = namespace("theory-pre-exams");

@Component({
  components: {
    FscSimpleCard,
    Table,
    PreExamsListQuestions,
  },
})
export default class PreExamsList extends Vue {
  public name = "PreExamsList";

  @TheoryPreExamsModule.Action("theoryPreExamsByStudentId")
  public theoryPreExamsByStudentId: any;

  @TheoryPreExamsModule.Getter("getDataList")
  public theoryPreExams: any;

  @Prop()
  public studentId!: any;

  public selectedPreExam: any = null;

  public fields = [
    {
      key: "date",
      label: this.$t("calendar.form_date"),
    },
    {
      key: "time",
      label: this.$t("calendar.form_time"),
    },
    {
      key: "studentEducation",
      label: this.$t("calendar.class"),
    },
    {
      key: "points",
      label: "Fp.",
    },
  ];

  public async mounted(): Promise<any> {
    if (this.studentId) await this.theoryPreExamsByStudentId(this.studentId);
  }

  public formatStudentEducation(education: any) {
    const licenseClass = education ? education.name : "";
    return licenseClass;
  }

  public pointsColor(passed: any) {
    if (!passed) {
      return `bg-danger d-flex justify-content-center`;
    }
    return `bg-success d-flex justify-content-center`;
  }

  public onPreExamRowClicked(preExam: any): void {
    this.selectedPreExam = preExam;
    this.$router.push({
      name: "PreExamsListQuestions",
      params: { id: String(preExam.id), class: preExam.studentEducation.name },
    });
  }

  public tableHeight() {
    if (this.selectedPreExam) {
      return `h-50`;
    }
    return `h-75`;
  }

  // @Watch("studentId", { immediate: true, deep: true})
  // public onStudentIdChange(id: any): void {
  //   if (id) {
  //     this.theoryPreExamsByStudentId(id);
  //   }
  // }
}
