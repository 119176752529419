














































import { Component } from "vue-property-decorator";
import LearnStatusManageAccount from "@/views/Student/LearnStatus/LearnStatusManageAccount.vue";
import FscPageHeader from "@/components/FscPageHeader.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import CrudService from "@/services/CrudService";
import PreliminaryExam from "@/views/Student/LearnStatus/PreliminaryExam/PreliminaryExam.vue";
import { AxiosResponse } from "axios";
import axios from "@/utils/axios";
import StudentQuestions from "@/views/Student/LearnStatus/StudentQuestions.vue";
import LearnStatusStatisticsTile from "@/views/Student/LearnStatus/LearnStatusStatisticsTile.vue";
import { IStudentEducation } from "@/interfaces/IStudentEducation";
import LearnStatusWrongQuestionTile from "@/views/Student/LearnStatus/LearnStatusWrongQuestionTile.vue";
import { mixins } from "vue-class-component";
import LearnStatusRequestMixin from "@/mixins/Request/LearnStatusRequestMixin";
import TheoryQuestionRequestMixin from "@/mixins/Request/TheoryQuestionRequestMixin";
import PreExamsList from "@/views/Student/LearnStatus/PreExamsList/PreExamsList.vue";
import { namespace } from "vuex-class";
import student from "@/router/Student";
import { formatStudentName } from "@/utils/NameUtil";

const crudService = new CrudService();
const URL_STUDENT_EDUCATIONS = "/student-educations";
const StudentModule = namespace("student");

@Component({
  components: {
    LearnStatusWrongQuestionTile,
    LearnStatusStatisticsTile,
    FscPageHeader,
    LearnStatusManageAccount,
    FscSimpleCard,
    StudentQuestions,
    PreliminaryExam,
    PreExamsList,
  },
})
export default class LearnStatus extends mixins(LearnStatusRequestMixin, TheoryQuestionRequestMixin) {
  public name = "LearnStatus";

  @StudentModule.Action("findOne")
  public findStudentById: any;

  @StudentModule.Getter("getDataItem")
  public student: any;

  @StudentModule.Action("update")
  public reactivateStudent: any;

  @StudentModule.Getter("getSuccess")
  public reactivationSuccess: any;

  public studentEducations: Array<IStudentEducation> = [];
  public allTheoryQuestionVersions: Array<any> = [];
  public questionsVersion: number | null = null;
  public topActiveEducation = null;

  public async mounted(): Promise<void> {
    await this.findStudentEducationsAction(this.studentId);
    this.fetchTheoryQuestionMoreThanTwoWrongAnswers(this.studentId);

    await this.findStudentById({
      resource: "students",
      id: this.studentId,
    });
  }

  protected get studentId(): number {
    const { id } = this.$route.params;
    return Number(id);
  }

  protected get studentFullName(): string {
    if (this.student) {
      return formatStudentName(this.student.firstName, this.student.lastName);
    } else {
      return "";
    }
  }

  public async onDriveBuzzToggle(options: { id: any; isEnabled: boolean }): Promise<void> {
    await this.toggleDriveBuzzEnabled({
      id: options.id,
      isEnabled: options.isEnabled,
    });
    await this.findStudentEducationsAction(this.studentId);
  }

  protected async findStudentEducationsAction(studentId: number): Promise<void> {
    return await crudService
      .findAll({
        resource: `student-educations/student/${studentId}`,
        descriptionField: "",
      })
      .then((response: AxiosResponse) => {
        this.studentEducations = response.data;
      });
  }

  protected async toggleDriveBuzzEnabled(options: { id: number; isEnabled: boolean }): Promise<void> {
    return await axios.post(`/student-educations/drive-buzz-enabled?id=${options.id}&driveBuzzEnabled=${options.isEnabled}`);
  }

  public async findQuestionsVersionByEducationId(educationId: number): Promise<void> {
    return crudService
      .findAll({
        resource: `${URL_STUDENT_EDUCATIONS}/${educationId}/theory-questions-version`,
        descriptionField: "",
      })
      .then((response: AxiosResponse) => {
        this.questionsVersion = response.data;
      });
  }

  public async findAllTheoryQuestionsVersions(): Promise<void> {
    return crudService
      .findAll({
        resource: `theory-question-versions`,
        descriptionField: "",
      })
      .then((response: AxiosResponse) => {
        this.allTheoryQuestionVersions = response.data;
      });
  }

  public changeActiveEducation(index: any) {
    this.topActiveEducation = index;
  }

  public async onReactivateStudent(studentId: any) {
    await this.reactivateStudent({
      resource: `students`,
      id: `enable?studentId=${studentId}`,
    });
    if (this.reactivationSuccess) {
      await this.findStudentById({
        resource: "students",
        id: this.studentId,
      });
    }
  }

  public async onChangedEducationsTab(tabIndex: number) {
    const selectedStudentEducation = this.studentEducations[tabIndex];
    if (selectedStudentEducation?.id) {
      await this.updateMetricsByStudentEducations([selectedStudentEducation]);
      this.fetchStudentQuestionAnswerMetricsByStudentEducations([selectedStudentEducation]);
    }
    this.changeActiveEducation(tabIndex);
  }
}
