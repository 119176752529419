





























































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import Table from "@/components/Table.vue";
import { namespace } from "vuex-class";
import { IStudentEducation } from "@/interfaces/IStudentEducation";
import FscModal from "@/components/Modal/FscModal.vue";

const StudentEducationModule = namespace("student-education");

@Component({
  components: {
    FscModal,
    FscSimpleCard,
    Table,
  },
})
export default class LearnStatusManageAccount extends Vue {
  public name = "LearnStatusManageAccount";

  @StudentEducationModule.Action("create")
  public createTheoryQuestionVersion: any;

  @StudentEducationModule.Action("resetEducationQuestionsByStudentEducationId")
  public resetQuestionsByStudentEducationId: any;

  @Prop({ type: Function })
  public findQuestionsVersionByEducationId!: (educationId: number) => Promise<void>;

  @Prop({ type: Object, default: () => null })
  public questionsVersion!: Record<any, any> | null;

  @Prop({ type: Function })
  public findAllTheoryQuestionsVersions!: () => Promise<void>;

  @Prop({ type: Array, default: () => [] })
  public allTheoryQuestionVersions!: Array<any>;

  @Prop({ type: Array, default: () => [] })
  public educationsByStudent!: Array<IStudentEducation>;
  private currentStudentEducationId: number | any = null;

  @Prop()
  public student!: any;

  public studentEducationFields = [
    {
      key: "mainLicenseClass",
      label: this.$tc("general.license_class", 1),
    },
    {
      key: "reset",
      label: this.$t("general.reset"),
    },
    {
      key: "questionsVersion",
      label: this.$t("general.questions_version"),
    },
  ];

  public educations: any = [];
  public confirmResetModalId = "confirm-reset-version";
  public reactivateButtonShow = false;
  public filteredOnlyActiveTheoryQuestionVersions: any = [];

  public get activeTheoryQuestionVersions() {
    return this.filteredOnlyActiveTheoryQuestionVersions;
  }
  public onVersionSelect(version: any): void {
    const obj = {
      studentEducationId: version.id,
      theoryQuestionsVersionId: version.selectedQuestionVersion.id,
    };
    this.createTheoryQuestionVersion({
      data: obj,
      resource: "student-educations/theory-questions-version",
    });
  }

  @Watch("allTheoryQuestionVersions")
  public onGetTheoryQuestionVersions(val: any) {
    this.filteredOnlyActiveTheoryQuestionVersions = this.allTheoryQuestionVersions.filter((version: any) => (version.active == true ? version : null));
  }
  @Watch("questionsVersion")
  public onQuestionsVersionsChange(version: any): void {
    if (version) {
      this.educations.map((education: any) => {
        if (education.id === version.studentEducationId) {
          education.selectedQuestionVersion = version;
        }
      });
    }
  }

  @Watch("educationsByStudent")
  public async onEducationsByStudentChange(education: any): Promise<void> {
    if (education) {
      this.educations = [];
      this.educationsByStudent.map((edc: any) => {
        this.findQuestionsVersionByEducationId(edc.id);
      });

      for (const education of this.educationsByStudent) {
        this.educations.push({
          selectedQuestionVersion: {
            id: null,
            name: null,
          },
          clicked: false,
          ...education,
        });
      }

      await this.findAllTheoryQuestionsVersions();
    }
  }

  public onDriveBuzzToggle(id: any, enabled: boolean): void {
    this.$emit("on-submit", {
      id: id,
      isEnabled: !enabled,
    });
  }

  public onOpenResetVersionModal(e: any) {
    this.currentStudentEducationId = e.id;
    this.$bvModal.show(this.confirmResetModalId);
  }

  public async onResetVersion() {
    this.$bvModal.hide(this.confirmResetModalId);
    await this.resetQuestionsByStudentEducationId(this.currentStudentEducationId);
    this.currentStudentEducationId = null;
    this.$root.$emit("refresh-page-after-update");
  }

  public onCancel() {
    this.currentStudentEducationId = null;
  }

  public onReactivateStudent() {
    if (this.student.driveBuzzLoginAllowed === false) {
      this.$emit("reactivate-student", this.student.id);
    } else {
      return;
    }
  }

  @Watch("student")
  public onStudentWatcher(student: any) {
    if (student) {
      this.reactivateButtonShow = !student.driveBuzzLoginAllowed;
    }
  }
}
