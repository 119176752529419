





































import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import StudentQuestionsImgNegative from "@/views/Student/LearnStatus/StudentQuestionsImgNegative.vue";
import StudentQuestionsImgNeutral from "@/views/Student/LearnStatus/StudentQuestionsImgNeutral.vue";
import StudentQuestionsImgPositive from "@/views/Student/LearnStatus/StudentQuestionsImgPositive.vue";

const StudentQuestionAnswersModule = namespace("student-question-answers");
const TheoryQuestionsModule = namespace("theory-questions");

@Component({
  components: {
    StudentQuestionsImgNegative,
    StudentQuestionsImgNeutral,
    StudentQuestionsImgPositive,
  },
})
export default class StudentQuestionsImage extends Vue {
  public name = "StudentQuestionsImage";

  @StudentQuestionAnswersModule.Action("getStats")
  public getStatusByEducationId: any;

  @StudentQuestionAnswersModule.Getter("getStats")
  public stats: any;

  @StudentQuestionAnswersModule.Getter("getIsLoading")
  public statsLoading: any;

  @TheoryQuestionsModule.Action("findLearningModeByEducationId")
  public findLearningModeByEducationId: any;

  @TheoryQuestionsModule.Getter("getDataItem")
  public learningMode: any;

  public learningModeDoubleChecked: any = "";

  @Prop()
  public educationId!: any;

  // public async mounted(): Promise<void> {
  //   if (this.educationId) {
  //     await this.getStatusByEducationId(this.educationId);
  //     await this.findLearningModeByEducationId(this.educationId);
  //   }
  // }

  @Watch("educationId", { immediate: true, deep: true })
  public async onStudentEducationIdChange(education: any): Promise<void> {
    if (education) {
      await this.getStatusByEducationId(education);
      await this.findLearningModeByEducationId(education);
      if (this.learningMode && this.learningMode.id === 2) {
        this.learningModeDoubleChecked = true;
      } else {
        this.learningModeDoubleChecked = false;
      }
    }
  }
}
