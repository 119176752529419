









import { Vue, Component, Prop } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import StudentQuestionsImage from "@/views/Student/LearnStatus/StudentQuestionsImage.vue";

@Component({
  components: {
    FscSimpleCard,
    StudentQuestionsImage,
  },
})
export default class StudentQuestions extends Vue {
  public name = "StudentQuestions";

  @Prop()
  public studentEducations!: any;

  @Prop()
  public topActiveEducation!: any;

  public studentEducationId() {
    if (this.studentEducations[this.topActiveEducation]) {
      return this.studentEducations[this.topActiveEducation].id;
    }
  }
}
